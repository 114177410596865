import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Nenalezeno" />
    <h1>Stránka nenalezena</h1>
    <p>Zkuste procházet web <Link to="/">od začátku</Link></p>
  </Layout>
)

export default NotFoundPage
